import Cookies from 'js-cookie';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import BadRequestException from './exceptions/BadRequestException';
import InternalServerErrorException from './exceptions/InternalServerErrorException';
import NotFoundException from './exceptions/NotFoundException';

export const baseURL = process.env.REACT_APP_BASE_URL;
export const baseURLLogin = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_AUTH}`;

export const errorMessages = {
  400: 'Algo deu errado. Tente novamente mais tarde.',
  404: 'Recurso não encontrado.',
  500: 'Erro de comunicação interna. Tente novamente mais tarde.',
  0: 'Erro genérico, exception não tratada.',
};

type TObjectEmpty = { [key: string]: string } | string;

export const generateErrorDataWithMessage = <T extends TObjectEmpty>(statusCode: keyof typeof errorMessages, errorData: T): string => {
  if (errorData) {
    if (Object.entries(errorData).length > 0 && errorData !== '{}') {
      if (Object.prototype.hasOwnProperty.call(errorData, 'msg')) {
        return JSON.stringify(errorData);
      }
      return JSON.stringify({
        ...(errorData as { [key: string]: string }),
        msg: errorMessages[statusCode],
      });
    }
    return JSON.stringify({ msg: errorMessages[statusCode] });
  }
  return JSON.stringify({ msg: errorMessages[statusCode] });
};

export const responseErrorInterceptor = (error: AxiosError): Promise<never> => {
  const HTTPStatusCode = error?.response?.status ? error.response.status : 0;
  const errorData = error?.response?.data ? error.response.data : errorMessages[0];

  switch (true) {
    case HTTPStatusCode === 400:
      throw new BadRequestException(generateErrorDataWithMessage<typeof errorData>(400, errorData));
    case HTTPStatusCode === 401:
      Cookies.remove('cpf');
      Cookies.remove('accessToken');
      window.location.href = window.location.origin;
      break;
    case HTTPStatusCode === 404:
      throw new NotFoundException(generateErrorDataWithMessage<typeof errorData>(404, errorData));
    case HTTPStatusCode >= 500:
      throw new InternalServerErrorException(generateErrorDataWithMessage<typeof errorData>(500, errorData));
    default:
      throw new Error(
        JSON.stringify({
          msg: `HTTP status code: ${HTTPStatusCode} - ${errorMessages[0]}`,
        }),
      );
  }

  return Promise.reject(error.response);
};

export const requestInterceptor = (request: AxiosRequestConfig): AxiosRequestConfig => {
  request.headers.Authorization = `Bearer ${Cookies.get('accessToken')}`;
  return request;
};

export const responseInterceptor = (response: AxiosResponse): AxiosResponse => response;
