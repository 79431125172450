import React, { FC, memo } from 'react';

import { IText } from './insterfaces';
import { TextStyle } from './styles';

const htmlTag: {
  [key: string]: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
} = {
  heading1Bold: 'h1',
  heading1Light: 'h1',
  heading2Bold: 'h2',
  heading2Light: 'h2',
  heading3Bold: 'h3',
  heading3Light: 'h3',
  heading4Bold: 'h4',
  heading4Light: 'h4',
  headlineBold: 'h5',
  headlineLight: 'h5',
  title: 'h6',
  subheading: 'p',
  bodyBold: 'p',
  bodyLight: 'p',
  caption: 'span',
  captionUppercase: 'span',
  button: 'span',
};

const Text: FC<IText> = ({
  type = 'bodyLight',
  bold = false,
  color = 'gray6',
  children,
  textTransform = 'none',
  textAlign = 'unset',
  margin = '0',
  tag,
  ...rest
}: IText) => (
  <TextStyle
    data-testid='text-test-id'
    margin={margin}
    type={type}
    bold={bold}
    color={color}
    textAlign={textAlign}
    textTransform={textTransform}
    as={tag || htmlTag[type]}
    {...rest}
  >
    {children}
  </TextStyle>
);

export default memo(Text);
