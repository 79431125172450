import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';

const styledBorderError = css`
  border-bottom: 2px solid ${({ theme }) => theme.colors.magenta2};

  &:active,
  &:focus,
  &:focus-within,
  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.colors.magenta2};
  }
`;

const styledDisabled = css`
  & .MuiFilledInput-input {
    color: ${({ theme }) => theme.colors.gray5};
  }

  &:active,
  &:focus,
  &:focus-within,
  &:hover {
    border-bottom-color: transparent;
  }
`;

export const InputCmp = styled(TextField)`
  & .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(12px, 10px) scale(1);
    ${({ theme }) => theme.fontTypes.captionUppercase};
    color: ${({ theme }) => theme.colors.gray5};
  }

  & .MuiFilledInput-root {
    background: ${({ theme }) => theme.colors.gray1};
    border-radius: 7px 7px 0px 0px;

    &:after {
      content: '';
      width: 100%;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray4};
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
    }

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      &:after {
        border-bottom: 2px solid ${({ theme }) => theme.colors.blue4};

        ${({ error, disabled, theme }) => (error || disabled) && `border-color: ${disabled ? theme.colors.gray4 : 'transparent'}`}
      }
    }

    ${({ error }) => error && styledBorderError};

    & .MuiInputAdornment-positionStart,
    & .MuiInputAdornment-positionEnd {
      margin-top: 0;
      transform: translateY(13px);
      height: 100%;

      & .MuiTypography-body1 {
        -webkit-tap-highlight-color: transparent;
        ${({ theme }) => theme.fontTypes.headlineBold};
        color: ${({ theme }) => theme.colors.gray6};

        ${({ value, theme }) => (value ? `color: ${theme.colors.gray6}` : `color: ${theme.colors.gray5}`)};

        ${({ disabled, theme }) => disabled && `color: ${theme.colors.gray6}`};
      }
    }

    & .MuiFilledInput-input {
      min-height: 69px;
      box-sizing: border-box;
      padding-top: 34px;
      padding-bottom: 10px;
      ${({ theme }) => theme.fontTypes.headlineBold};
      color: ${({ theme }) => theme.colors.gray6};

      &[type='password']::-ms-reveal,
      &[type='password']::-ms-clear {
        display: none;
      }
    }

    ${({ disabled }) => disabled && styledDisabled};
  }

  & .MuiFormHelperText-root {
    margin-top: ${({ theme }) => theme.spacing(1)};
    ${({ theme }) => theme.fontTypes.bodyBold};
    &.Mui-error {
      color: ${({ theme }) => theme.colors.magenta2};
    }
  }

  & input::placeholder,
  & input::-webkit-input-placeholder,
  & input:-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.gray4};
  }
`;

export const WrapperTextError = styled.span`
  display: flex;

  & > svg {
    margin-right: ${({ theme }) => theme.spacing(1)};
    transform: translateY(2px);
  }
`;
