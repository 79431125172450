import styled from 'styled-components';
import marketingLogin from 'assets/images/marketingLogin.png';
import sphere from 'assets/images/sphere.svg';
import { IconExporter } from 'components';
import logo from 'assets/images/logo_conquista_white.svg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  position: relative;
  background: ${({ theme }) => theme.colors.blue4};
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperForm = styled.div``;

export const LoginForm = styled.form`
  background: ${({ theme }) => theme.colors.white1};
  border-radius: 4px;
  margin: 0 ${({ theme }) => theme.spacing(16)} 0;
  min-width: 414px;
  padding: ${({ theme }) => theme.spacing(4)};
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(4)};

  @media ${({ theme }) => theme.mediaQueries.md} {
    min-width: 250px;
    max-width: 310px;
  }

  @media ${({ theme }) => theme.mediaQueries.xs} {
    margin: 0 ${({ theme }) => theme.spacing(3)};
  }

  & .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.colors.gray5};
  }

  & button {
    max-width: inherit;
    width: 100%;
  }
`;
export const ButtonReturn = styled(IconExporter).attrs(() => ({
  name: 'arrow',
  color: 'white1',
  height: 20,
}))`
  position: absolute;
  left: ${({ theme }) => theme.spacing(8)};
  top: ${({ theme }) => theme.spacing(4)};
  cursor: pointer;
  z-index: 55;
  @media ${({ theme }) => theme.mediaQueries.xs} {
    left: ${({ theme }) => theme.spacing(3)};
  }
`;
export const ButtonExit = styled(IconExporter).attrs(() => ({
  name: 'close',
  color: 'white1',
  height: 20,
}))`
  position: absolute;
  right: ${({ theme }) => theme.spacing(8)};
  top: ${({ theme }) => theme.spacing(4)};
  cursor: pointer;
  z-index: 55;
  @media ${({ theme }) => theme.mediaQueries.xs} {
    right: ${({ theme }) => theme.spacing(3)};
  }
`;

export const LogoConquista = styled.img.attrs(() => ({
  src: logo,
  alt: 'logo',
}))`
  padding: ${({ theme }) => theme.spacing(3)} 0 ${({ theme }) => theme.spacing(4)};
`;

export const ContainerContent = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const ContainerForm = styled.div`
  display: grid;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContainerCenter = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
`;

export const Logo = styled.div`
  margin: ${({ theme }) => theme.spacing(8)} 0;
`;

export const ContainerBackgroundPorto = styled.div`
  display: none;
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    display: flex;
    height: 100vh;
    width: 100%;
    background-image: url(${marketingLogin});
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const BackgroundBall = styled.div`
  display: flex;
  height: 100vh;
  min-width: 600px;
  width: 100%;
  position: absolute;
  right: 0;
  opacity: 0.8;
  background-image: url(${sphere});
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${({ theme }) => theme.mediaQueries.sm} and (orientation: landscape) {
    display: none !important;
  }

  @media ${({ theme }) => theme.mediaQueries.sm} and (orientation: portrait) {
    display: none !important;
  }
`;
