import styled from 'styled-components';

import OpenAccountImageDesk from 'assets/images/background_section_open_account_desk.png';
import OpenAccountImageDotsDesk from 'assets/images/bg_dots_section_open_account_desk.png';
import OpenAccountImageDotsTabHor from 'assets/images/bg_dots_section_open_account_tab_hor.png';
import OpenAccountImageDotsTabVer from 'assets/images/bg_dots_section_open_account_tab_ver.png';
import { Text } from 'components';

export const SectionOpenAccount = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.lineaGradient.button.primaryBlue};
  min-height: 768px;

  z-index: 0;

  @media (min-width: 751px) and (max-width: 1024px) {
    min-height: 623px;
  }

  @media (max-width: 750px) {
    min-height: unset;
    position: unset;
    padding: ${({ theme }) => theme.spacing(5)} ${({ theme }) => theme.spacing(3)};
  }
`;

export const ImageWomanOpenAccout = styled.div`
  position: absolute;
  height: 100%;
  width: 100vw;
  max-width: 1366px;
  background-position: top;
  background-image: none;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: 1;

  @media (min-width: 1025px) {
    background-image: url(${OpenAccountImageDesk});
  }
`;

export const ImageEllipseOpenAccout = styled.div`
  position: absolute;
  height: 100%;
  width: 100vw;
  max-width: 1366px;
  background-image: none;

  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: 2;

  @media (min-width: 1025px) {
    background-image: url(${OpenAccountImageDotsDesk});
  }

  @media (min-width: 901px) and (max-width: 1024px) {
    background-image: url(${OpenAccountImageDotsTabHor});
  }

  @media (min-width: 751px) and (max-width: 900px) {
    background-image: url(${OpenAccountImageDotsTabVer});
  }
`;

export const ContainerContent = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  max-width: 1366px;
  padding: 0 ${({ theme }) => theme.spacing(3)};

  z-index: 3;

  @media (min-width: 1025px) {
    padding: 0 ${({ theme }) => theme.spacing(6)};
  }

  @media (min-width: 1367px) {
    padding: 0;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    position: unset;
    padding: 0;

    & > div {
      max-width: 100vw;
    }
  }
`;

export const ContainerContentLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 391px;
`;

export const ContainerContentRight = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 750px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled(Text).attrs(props => ({
  color: 'white1',
  type: props.type,
}))`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const SubTitle = styled(Text).attrs(() => ({
  color: 'white1',
  type: 'heading4Bold',
}))`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

export const LoginForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 424px;
  padding: ${({ theme }) => theme.spacing(6)};
  background: ${({ theme }) => theme.colors.white1};
  border-radius: 7px;

  & p {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }

  & h3,
  & h4,
  & > div {
    margin-bottom: ${({ theme }) => theme.spacing(4)};

    @media (max-width: 750px) {
      margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
  }

  & button {
    max-width: inherit;
    width: 100%;
    max-height: 35px;
  }

  @media (max-width: 1023px) {
    max-width: 296px;
  }

  @media (max-width: 750px) {
    padding: ${({ theme }) => theme.spacing(4)};
    max-width: unset;
    width: 100% !important;
  }
`;
