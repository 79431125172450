import React, { SVGAttributes, FC, FunctionComponentElement, memo } from 'react';
import { themeColors } from 'styles/theme';
import { ReactComponent as loading } from 'assets/icons/loading.svg';
import { ReactComponent as add } from 'assets/icons/add.svg';
import { ReactComponent as information } from 'assets/icons/information.svg';
import { ReactComponent as close } from 'assets/icons/close.svg';
import { ReactComponent as time } from 'assets/icons/time.svg';
import { ReactComponent as blocked } from 'assets/icons/blocked.svg';
import { ReactComponent as lock } from 'assets/icons/lock.svg';
import { ReactComponent as check } from 'assets/icons/check.svg';
import { ReactComponent as arrow } from 'assets/icons/arrow.svg';
import { ReactComponent as breadcrumb } from 'assets/icons/breadcrumb.svg';
import { ReactComponent as highlightOff } from 'assets/icons/highlightOff.svg';
import { ReactComponent as taskAlt } from 'assets/icons/taskAlt.svg';

export const dict = {
  loading,
  add,
  information,
  close,
  time,
  blocked,
  lock,
  check,
  arrow,
  breadcrumb,
  taskAlt,
  highlightOff,
};

export interface IIconExporter extends SVGAttributes<SVGElement> {
  name: keyof typeof dict;
  color?: keyof typeof themeColors;
}

export type TIconExporterReturn = FunctionComponentElement<IIconExporter> | null;

const IconExporter: FC<IIconExporter> = ({
  name,
  color = 'gray4',
  width = 21,
  height = 12,
  ...rest
}: IIconExporter): TIconExporterReturn => {
  const Icon = dict[name];
  return <Icon role='img' aria-label={name} height={height} fill={themeColors[color]} width={width} {...rest} />;
};

export default memo(IconExporter);
