import React, { FC } from 'react';

import * as s from './styles';
import { IMain } from './types';

const Main: FC<IMain> = ({ children }) => {
  return <s.Main>{children}</s.Main>;
};

export default Main;
