import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';
import { IconExporter } from 'components';
import { IButton, IAddIcon } from './types';

const handleAddIcon = (isDirection: boolean, space: string): string => {
  if (isDirection) {
    return `translateX(${space})`;
  }

  return `translateX(-${space})`;
};

const handleColorType = (
  model?: 'primary-blue' | 'primary-green' | 'secondary' | 'link' | 'virtual keyboard' | 'add' | 'virtual keyboard clean' | 'card',
) => {
  switch (model) {
    case 'primary-blue':
      return css`
        background: ${({ theme }) => theme.lineaGradient.button.primary};
        color: ${({ theme }) => theme.colors.white1};

        &:hover,
        &:focus {
          background: ${({ theme }) => theme.lineaGradient.button.primaryBlue};
        }

        &:active {
          background: ${({ theme }) => theme.lineaGradient.button.primaryBlue};
          color: ${({ theme }) => theme.colors.white3};

          & > span {
            svg {
              fill: ${({ theme }) => theme.colors.white3};
            }
          }
        }
      `;
    case 'primary-green':
      return css`
        background: ${({ theme }) => theme.lineaGradient.button.secondary};
        color: ${({ theme }) => theme.colors.white1};

        &:hover,
        &:focus {
          background: ${({ theme }) => theme.lineaGradient.button.primaryGreen};
        }

        &:active {
          background: ${({ theme }) => theme.lineaGradient.button.primaryGreen};
          color: ${({ theme }) => theme.colors.white3};

          & > span {
            svg {
              fill: ${({ theme }) => theme.colors.white3};
            }
          }
        }
      `;
    case 'secondary':
      return css`
        background: ${({ theme }) => theme.colors.white1};
        border: 1px solid ${({ theme }) => theme.colors.blue4};
        color: ${({ theme }) => theme.colors.blue4};
        box-sizing: border-box;
      `;
    case 'link':
      return css`
        &&& {
          background-color: transparent;
          border: 0;
          padding: 0;
          width: auto;

          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
        color: ${({ theme }) => theme.colors.blue4};
      `;
    default:
      return css`
        background-color: transparent;
        border: 0;
        padding: 0;
        width: auto;
        color: ${({ theme }) => theme.colors.blue4};

        &:hover,
        &:focus {
          background-color: transparent;
        }
      `;
  }
};

const styleDisabled = css`
  background: ${({ theme }) => theme.colors.gray2};
  color: ${({ theme }) => theme.colors.gray4};
  border: 1px solid ${({ theme }) => theme.colors.gray3};
`;

/**
 * Exceptionally:
 * Button Padding-top and Padding-bottom 10px
 * Designer System: https://www.figma.com/file/gbiEXDhVQQTyvLgL0Jr4yr/Conquista---Design-System?node-id=20%3A1354
 */
export const ButtonComponent = styled(Button).attrs(({ size }) => ({
  fullWidth: size === 'large',
}))<IButton>`
  border-radius: 4px;
  padding: 0 ${({ theme }) => theme.spacing(4)};
  box-sizing: border-box;
  min-height: 35px;
  ${({ theme }) => theme.fontTypes.button};
  ${({ model }) => handleColorType(model)};
  ${({ disabled }) => disabled && styleDisabled}
`;

export const VetorRightIcon = styled(props => <IconExporter {...props} />)``;

export const InformationIcon = styled(props => <IconExporter {...props} />)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const AddIcon = styled(props => <IconExporter {...props} />)<IAddIcon>`
  transform: ${({ direction, theme }) => handleAddIcon(direction === 'left', theme.spacing(1))};
`;

export const IndicatorIcon = styled(props => <IconExporter {...props} />)`
  margin: 0 -${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1)} -${({ theme }) => theme.spacing(1)};
`;
