import React, { FC } from 'react';
import Cookies from 'js-cookie';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as Page from 'pages';

const Routes: FC = () => {
  const handleRedirect = () => {
    const pathname = Cookies.get('cpf') && Cookies.get('accessToken') ? '/perfil' : '/login';

    return (
      <Redirect
        to={{
          pathname,
        }}
      />
    );
  };

  return (
    <Switch>
      <Route exact path='/' component={Page.Home} />

      <Route path='/cadastro'>
        <Page.Register />
      </Route>
      <Route path='*' render={handleRedirect} />
    </Switch>
  );
};

export default Routes;
