import { createSlice } from '@reduxjs/toolkit';
import { Toast } from 'helpers';
import i18n from 'plugins/i18n';
import { RootState } from 'store';
import { fetchLogin } from './thunk.store';
import { ILoginPayload } from './types.store';

export interface IAuthInitialState {
  payload: ILoginPayload;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

export const initialState: IAuthInitialState = {
  payload: {
    access_token: '',
    token_type: '',
    expires_in: 0,
    refresh_token: '',
    scope: '',
  },
  loading: 'idle',
};

const store = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    cleanAuth: state => {
      state.payload = initialState.payload;
      state.loading = 'idle';
    },
  },
  extraReducers: {
    [fetchLogin.fulfilled.type]: (state, { payload }) => {
      state.payload = payload;
      state.loading = 'succeeded';
    },
    [fetchLogin.rejected.type]: (state, action) => {
      const messageI18n = (action && action.payload && action.payload.error) || 'login_required';
      Toast('error', 'Login', i18n.t(`api_error_summary.${messageI18n}`));
      state.payload = initialState.payload;
      state.loading = 'failed';
    },
    [fetchLogin.pending.type]: state => {
      state.loading = 'pending';
      state.payload = initialState.payload;
    },
  },
});

export const selectAuth = (state: RootState): IAuthInitialState => state.auth;

export const { cleanAuth } = store.actions;

export default store.reducer;
