import i18n from 'plugins/i18n';
import { cpf } from 'cpf-cnpj-validator';
import * as yup from 'yup';

export const schemaLogin = yup.object().shape({
  login: yup.mixed().test('is-valid', i18n.t('form.errors.cpf'), (value: string) => {
    if (value && value.length === 14) {
      return cpf.isValid(value);
    }

    return true;
  }),
  password: yup.string(),
});
