import React from 'react';

import { HashRouter } from 'react-router-dom';
import Routes from 'routes';
import 'react-toastify/dist/ReactToastify.css';
import { injectStyle } from 'react-toastify/dist/inject-style';

import AppProviders from 'utils/AppProviders';
import { Toast as ToastCmp } from 'components';
import GlobalStyle from 'styles/global';
import { Header, Main } from './components';

import './styles/fonts.css';

injectStyle();

const App: React.FC = () => {
  return (
    <AppProviders>
      <HashRouter>
        <GlobalStyle />
        <ToastCmp />
        <Header />
        <Main>
          <Routes />
        </Main>
        <footer>Footer</footer>
      </HashRouter>
    </AppProviders>
  );
};

export default App;
