import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
  }

  body {
    scroll-behavior: smooth;
  }

  html, body {
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.gray1};
  }

  html.noOverflow {
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.gray6};
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
  }

  .MuiList-root {
    padding: 0;
  }

  .popper-autocomplete {
    & .MuiAutocomplete-paper {
      margin: 0;
      background: ${({ theme }) => theme.colors.white1};
      box-shadow: 0px 4px 8px rgba(39, 39, 39, 0.14);
      border-radius: 0px 0px 4px 4px;

      & .MuiAutocomplete-listbox {
        padding: 0;

        & .MuiAutocomplete-option {
          min-height: 49px;
          padding: 0 ${({ theme }) => theme.spacing(2)} 0 ${({ theme }) => theme.spacing(3)};

          &[aria-selected="true"] {
            background-color: ${({ theme }) => theme.colors.white1};
            &:before {
              position: absolute;
              left: 0;
              content: '';
              width: 3px;
              height: 43px;
              background-color: ${({ theme }) => theme.colors.blue4};
            }
          }
          
          &[data-focus="true"] {
            background: ${({ theme }) => theme.colors.gray1};
            border-radius: 1px;
          }
        }
      }
    }
  }
`;
