import styled from 'styled-components';

export const ContainerToast = styled.div`
  .Toastify__toast-container {
    top: 70px;
  }
  .Toastify__toast {
    background: ${({ theme }) => theme.colors.white1};
    box-sizing: border-box;
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.gray6};
    min-height: 73px;
    max-height: max-content;
    padding: ${({ theme }) => theme.spacing(2)};
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
  .Toastify__toast--error {
    border: 1px solid ${({ theme }) => theme.colors.magenta2};
    box-shadow: 0px 4px 8px ${({ theme }) => theme.shadows.magenta3};
  }
  .Toastify__toast--info {
    border: 1px solid ${({ theme }) => theme.colors.gray4};
    box-shadow: 0px 4px 8px ${({ theme }) => theme.shadows.gray4};
  }
  .Toastify__toast--success {
    border: 1px solid ${({ theme }) => theme.colors.green3};
    box-shadow: 0px 4px 8px ${({ theme }) => theme.shadows.green3};
  }
  .Toastify__progress-bar {
    opacity: 0;
  }
  .Toastify__close-button {
    color: ${({ theme }) => theme.colors.gray4};

    & > svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const ToastifyContent = styled.div`
  display: flex;

  & > svg {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const ToastifyWrapperTexts = styled.div`
  display: flex;
  flex-direction: column;

  & > h6 {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
