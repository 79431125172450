import React from 'react';
import InputMask from 'react-input-mask';
import { InputAdornment, TextFieldProps } from '@material-ui/core';
import { IconExporter, Loading } from 'components';
import * as s from './styles';

type OmitInput = 'placeholder' | 'id' | 'disabled' | 'name';

interface IInput extends Omit<TextFieldProps, OmitInput> {
  preffix?: React.ReactNode;
  suffix?: React.ReactNode;
  name?: string;
  id?: string;
  value?: string;
  placeholder: string;
  label: string;
  helperText?: React.ReactNode;
  onClickPreffix?: () => void;
  onClickSuffix?: () => void;
  isLoading?: boolean;
  isAdd?: boolean;
  disabled?: boolean;
  isBlocked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  mask?: string;
  maxLength?: number;
  minLength?: number;
  max?: number;
  min?: number;
}

const Input = React.forwardRef<HTMLInputElement, IInput>(
  (
    {
      preffix,
      suffix,
      helperText,
      label,
      value,
      placeholder,
      onChange,
      onClickPreffix,
      onClickSuffix,
      isLoading,
      isAdd,
      isBlocked,
      disabled,
      id,
      name,
      mask,
      onBlur,
      ...rest
    },
    ref,
  ) => {
    const handlePreffix = (): JSX.Element | undefined => {
      if (preffix) {
        return (
          <InputAdornment
            position='start'
            onClick={onClickPreffix}
            style={{
              cursor: onClickPreffix ? 'pointer' : 'auto',
            }}
          >
            {preffix}
          </InputAdornment>
        );
      }

      return undefined;
    };

    const handleSuffix = (): JSX.Element | undefined => {
      if (isLoading) {
        return (
          <InputAdornment position='end'>
            <Loading />
          </InputAdornment>
        );
      }

      if (disabled) {
        return (
          <InputAdornment position='end'>
            <IconExporter name='lock' height={21} color='gray5' />
          </InputAdornment>
        );
      }

      if (rest.type === 'password') {
        return (
          <InputAdornment
            position='end'
            onClick={onClickSuffix}
            style={{
              cursor: onClickSuffix ? 'pointer' : 'auto',
            }}
          >
            {suffix}
          </InputAdornment>
        );
      }

      if (isAdd) {
        return (
          <InputAdornment
            position='end'
            style={{
              cursor: onClickSuffix ? 'pointer' : 'auto',
            }}
            onClick={onClickSuffix}
          >
            <IconExporter name='add' height={21} color='gray5' />
          </InputAdornment>
        );
      }

      if (isBlocked) {
        return (
          <InputAdornment position='end'>
            <IconExporter name='blocked' height={21} color='gray5' />
          </InputAdornment>
        );
      }

      if (suffix) {
        return (
          <InputAdornment
            style={{
              cursor: onClickSuffix ? 'pointer' : 'auto',
            }}
            onClick={onClickSuffix}
            position='end'
          >
            {suffix}
          </InputAdornment>
        );
      }

      return undefined;
    };

    const handlerHelperText = (): React.ReactNode => {
      if (rest.error) {
        return (
          <s.WrapperTextError>
            <IconExporter name='information' color='magenta2' width={16} height={16} />
            <span>{helperText}</span>
          </s.WrapperTextError>
        );
      }

      return helperText;
    };

    if (mask) {
      return (
        <InputMask
          data-testid='input-test-id'
          maskPlaceholder={null}
          mask={mask}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled || isBlocked}
        >
          <s.InputCmp
            data-testid='input-component-id'
            id={id}
            label={label}
            name={name}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              disableUnderline: true,
              startAdornment: handlePreffix(),
              endAdornment: handleSuffix(),
            }}
            helperText={handlerHelperText()}
            variant='filled'
            fullWidth
            {...rest}
          />
        </InputMask>
      );
    }

    return (
      <s.InputCmp
        data-testid='input-component-id'
        id={id}
        label={label}
        name={name}
        placeholder={placeholder}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          disableUnderline: true,
          startAdornment: handlePreffix(),
          endAdornment: handleSuffix(),
        }}
        helperText={handlerHelperText()}
        onBlur={onBlur}
        variant='filled'
        onChange={onChange}
        value={value}
        disabled={disabled || isBlocked}
        fullWidth
        inputRef={ref}
        {...rest}
      />
    );
  },
);

export default React.memo(Input);
