import { css, DefaultTheme as DefaultThemeStyleComponent } from 'styled-components';
import { IFontTypes, IGridConfig, ILineaGradient, IMediaQueries, IShadows, IThemeColors } from './types';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: IThemeColors;
    fontTypes: IFontTypes;
    fontFamily: string;
    gutter: number;
    grid: IGridConfig;
    mediaQueries: IMediaQueries;
    lineaGradient: ILineaGradient;
    shadows: IShadows;
    spacing: (x: number) => string;
  }
}

export const themeColors: IThemeColors = {
  gray1: '#F1F1F1',
  gray2: '#EBEBEB',
  gray3: '#DDDDDD',
  gray4: '#BABABA',
  gray5: '#898989',
  gray6: '#272727',
  grayBlue1: '#555861',
  white1: '#FFFFFF',
  white2: 'rgba(255, 255, 255, 0.7)',
  white3: 'rgba(255, 255, 255, 0.5)',
  white4: 'rgba(255, 255, 255, 0.15)',
  darkBlue1: '#E6F7FF',
  darkBlue2: '#004E70',
  blue1: '#EFFBFF',
  blue2: '#C8F0FF',
  blue3: '#A1E5FF',
  blue4: '#00ADEF',
  blue5: '#018CB7',
  green1: '#EEF8F2',
  green2: '#CFE8D2',
  green3: '#57B063',
  green4: '#46A166',
  green5: '#2F736B',
  yellow1: '#FFF3C4',
  yellow2: '#FFCD00',
  magenta1: '#FAD2E3',
  magenta2: '#E72176',
};

export const shadows: IShadows = {
  gray1: '0px 1px 2px rgba(39, 39, 39, 0.14)',
  gray2: '0px 2px 4px rgba(39, 39, 39, 0.14)',
  gray3: '0px 4px 8px rgba(39, 39, 39, 0.14)',
  gray4: '0px 7px 21px rgba(39, 39, 39, 0.14)',
  blue1: '0px 3px 6px rgba(0, 173, 239, 0.14)',
  magenta3: '0px 4px 8px rgba(231, 33, 118, 0.14)',
  green3: '0px 4px 8px rgba(87, 176, 99, 0.14)',
};

export const objectivesColorGuide: string[] = [
  themeColors.magenta2,
  themeColors.yellow2,
  themeColors.green4,
  themeColors.darkBlue2,
  themeColors.darkBlue1,
  themeColors.green5,
  themeColors.magenta1,
];

export const gridConfig: IGridConfig = {
  breakpoints: {
    xl: 1280,
    lg: 1280,
    md: 1024,
    sm: 736,
    xs: 480,
  },
  row: {
    padding: 7,
  },
  col: {
    padding: 7,
  },
  container: {
    padding: 7,
    maxWidth: {
      xl: 1280,
      lg: 1280,
      md: 1024,
      sm: 736,
      xs: 480,
    },
  },
};

export const mediaQueries: IMediaQueries = {
  xs: `(max-width: ${gridConfig.breakpoints.xs}px)`,
  sm: `(max-width: ${gridConfig.breakpoints.sm}px)`,
  md: `(max-width: ${gridConfig.breakpoints.md}px)`,
  lg: `(max-width: ${gridConfig.breakpoints.lg}px)`,
  xl: `(max-width: ${gridConfig.breakpoints.xl}px)`,
  smUp: `(min-width: ${gridConfig.breakpoints.sm}px)`,
  mdUp: `(min-width: ${gridConfig.breakpoints.md}px)`,
  lgUp: `(min-width: ${gridConfig.breakpoints.lg}px)`,
  xlUp: `(min-width: ${gridConfig.breakpoints.xl}px)`,
};

export const fontTypes: IFontTypes = {
  heading1Bold: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 92px;
    line-height: 125px;
  `,
  heading1Light: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 92px;
    line-height: 125px;
  `,
  heading2Bold: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 76px;
    letter-spacing: 1px;
  `,
  heading2Light: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 56px;
    line-height: 76px;
    letter-spacing: 1px;
  `,
  heading3Bold: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: 0.5px;
  `,
  heading3Light: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: 0.5px;
    font-weight: 300;
  `,
  heading4Bold: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
  `,
  heading4Light: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 22px;
    line-height: 30px;
  `,
  headlineBold: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
  `,
  headlineLight: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
  `,
  title: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.25px;
  `,
  subheading: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  `,
  bodyBold: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
  `,
  bodyLight: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
  `,
  caption: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  `,
  captionUppercase: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  button: css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  `,
};

const lineaGradient: ILineaGradient = {
  button: {
    primary: `linear-gradient(135deg, ${themeColors.blue5} 0%, ${themeColors.blue4} 120%)`,
    secondary: `linear-gradient(90deg, ${themeColors.green3} 0%, ${themeColors.green5} 201.79%);`,
    primaryBlue: `linear-gradient(90deg, rgba(0, 78, 112, 0.4) 0.01%, rgba(0, 78, 112, 0.4) 99.99%, rgba(0, 78, 112, 0) 100%), linear-gradient(90deg, ${themeColors.blue4} 0%, ${themeColors.blue5} 146.43%)`,
    primaryGreen: `linear-gradient(90deg, rgba(0, 78, 112, 0.4) 0.01%, rgba(0, 78, 112, 0.4) 99.99%, rgba(0, 78, 112, 0) 100%), linear-gradient(90deg, ${themeColors.green3} 0%, ${themeColors.green5} 201.79%)`,
  },
};

const theme: DefaultThemeStyleComponent = {
  colors: themeColors,
  fontTypes,
  fontFamily: 'Open Sans',
  gutter: 14,
  grid: gridConfig,
  mediaQueries,
  lineaGradient,
  shadows,
  spacing: (number: number): string => `${number * 7}px`,
};

export default theme;
