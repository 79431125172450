import React, { FC } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Input, Text } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { mediaQueries } from 'styles/theme';

import { schemaLogin } from './utils';
import * as s from './styles';

export interface IFormInputs {
  login: string;
  password: string;
}

const Home: FC = () => {
  const { t } = useTranslation();
  const matchesSmUp = useMediaQuery(`@media ${mediaQueries.smUp} `);

  const {
    formState: { errors },
    control,
  } = useForm<IFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(schemaLogin),
    defaultValues: { login: '', password: '' },
  });

  return (
    <s.SectionOpenAccount>
      <s.ImageWomanOpenAccout />
      <s.ImageEllipseOpenAccout />
      <s.ContainerContent>
        <s.ContainerContentLeft>
          <s.Title type={matchesSmUp ? 'heading2Light' : 'heading3Light'}>{t('home.title')}</s.Title>
          <s.SubTitle>{t('home.subtitle')}</s.SubTitle>
        </s.ContainerContentLeft>
        <s.ContainerContentRight>
          <s.LoginForm onSubmit={() => console.log('Submit Button clicked !')}>
            <Text type='subheading' color='gray5'>
              {t('home.form.title')}
            </Text>
            <Text type={matchesSmUp ? 'heading3Light' : 'heading4Light'} color='gray6'>
              {t('home.form.primary_heading')}
            </Text>
            <Controller
              render={({ field }) => (
                <Input
                  label='cpf'
                  placeholder='Ex: 000.000.000-00'
                  error={!!errors.login}
                  helperText={errors.login?.message}
                  inputProps={{ 'data-testid': 'login-id' }}
                  mask='999.999.999-99'
                  {...field}
                />
              )}
              control={control}
              name='login'
            />
            <Button model='primary-blue' disabled type='submit'>
              {t('home.form.button.content')}
            </Button>
          </s.LoginForm>
        </s.ContainerContentRight>
      </s.ContainerContent>
    </s.SectionOpenAccount>
  );
};

export default Home;
