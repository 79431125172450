import { makeStyles, createStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import theme from 'styles/theme';

export const HiddenMobile = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const HiddenTablet = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
`;

export const MarginLeftDiv = styled.div`
  margin-left: auto;
`;

const useStyles = makeStyles(() =>
  createStyles({
    rootAppBar: {
      backgroundColor: theme.colors.white1,
      '&.MuiPaper-elevation4': {
        boxShadow: theme.shadows.gray4,
      },
    },
    rootToolBar: {
      height: '70px',
    },
    rootToolBarBottom: {
      minHeight: '70px',
    },
    rootPaper: {
      flexGrow: 1,
      marginLeft: theme.spacing(11),
    },
    rootTabs: {
      '& .MuiTabs-scroller > span': {
        backgroundColor: theme.colors.blue4,
        height: '3px',
        borderRadius: '3px',
      },
    },
    rootTab: {
      height: '70px',
      fontSize: '18px',
      textTransform: 'capitalize',
      lineHeight: '24.51px',
      fontWeight: 400,
      color: theme.colors.gray5,
      minWidth: 0,
      padding: `${theme.spacing(3)} 0`,
      '&:not(:first-child)': {
        marginLeft: theme.spacing(8),
      },
      '&.MuiTab-textColorPrimary.Mui-selected': {
        color: theme.colors.gray6,
      },
    },
    gutters: {
      '@media (min-width: 0)': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      '@media (min-width: 768px)': {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      '@media (min-width: 1366px)': {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
      },
    },
  }),
);

export { useStyles };
