import React, { FC } from 'react';

import { Loading } from 'components';
import { TIconExporterReturn } from 'components/types';
import { themeColors } from 'styles/theme';

import { IButton } from './types';
import * as s from './styles';

const Button: FC<IButton> = ({
  model = 'primary-blue',
  children,
  variant = 'contained',
  label,
  preffix,
  suffix,
  isLoading,
  isLoadingProps = {},
  disabled,
  ...rest
}) => {
  const preffixSuffixIconColor = (): keyof typeof themeColors => {
    if (disabled) {
      return model === 'link' ? 'gray4' : 'gray5';
    }

    switch (model) {
      case 'primary-blue':
      case 'primary-green':
        return 'white1';
      case 'secondary':
        return 'blue4';
      default:
        return 'blue4';
    }
  };

  const SuffixRender = (): TIconExporterReturn => {
    switch (suffix) {
      case 'add':
        return <s.AddIcon direction='left' height={21} name='add' color={preffixSuffixIconColor()} />;
      case 'vetor-right':
        return <s.VetorRightIcon name='vetor' color={preffixSuffixIconColor()} />;
      case 'indicator-vetor':
        return (
          <>
            <s.IndicatorIcon height={8} name='indicator' color='magenta2' />
            <s.VetorRightIcon name='vetor' color={preffixSuffixIconColor()} />
          </>
        );
      default:
        return null;
    }
  };

  const PreffixRender = (): TIconExporterReturn => {
    switch (preffix) {
      case 'add':
        return <s.AddIcon direction='right' height={21} name='add' color={preffixSuffixIconColor()} />;
      case 'information':
        return <s.InformationIcon height={21} name='information' color={preffixSuffixIconColor()} />;
      case 'vetor-right':
        return <s.VetorRightIcon name='vetor' color={preffixSuffixIconColor()} />;
      default:
        return null;
    }
  };

  return (
    <s.ButtonComponent
      data-testid='button-test-id'
      model={model}
      type='button'
      color='inherit'
      variant={variant}
      disableRipple
      disableElevation
      disableFocusRipple
      disabled={Boolean(disabled)}
      {...rest}
    >
      {isLoading ? (
        <Loading {...isLoadingProps} color={preffixSuffixIconColor()} />
      ) : (
        <>
          <PreffixRender />
          {label || children}
          <SuffixRender />
        </>
      )}
    </s.ButtonComponent>
  );
};

export default Button;
