import React from 'react';
import { AppBar, Toolbar, useScrollTrigger, Paper, Tabs, Tab } from '@material-ui/core';

import Logo from 'assets/images/logo-conquista.svg';
import MobileMenu from 'assets/images/menu-responsivo.svg';
import { Button } from '../..';
import { IElevationScroll } from './types';
import * as s from './styles';

function ElevationScroll(props: IElevationScroll) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children as React.ReactElement, {
    elevation: trigger ? 4 : 0,
  });
}

const Header = (props: IElevationScroll): JSX.Element => {
  const classes = s.useStyles();
  const [value, setValue] = React.useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar classes={{ root: classes.rootAppBar }}>
          <Toolbar variant='dense' classes={{ root: classes.rootToolBar, gutters: classes.gutters }}>
            <s.FlexDiv>
              <img src={Logo} alt='Logo Conquista' />
              <s.HiddenMobile>
                <Paper classes={{ root: classes.rootPaper }} elevation={0}>
                  <Tabs
                    classes={{ root: classes.rootTabs }}
                    value={value}
                    onChange={handleChange}
                    indicatorColor='primary'
                    textColor='primary'
                    centered
                  >
                    <Tab classes={{ root: classes.rootTab }} label='início' disableRipple />
                    <Tab classes={{ root: classes.rootTab }} label='produtos' disableRipple />
                    <Tab classes={{ root: classes.rootTab }} label='contato' disableRipple />
                  </Tabs>
                </Paper>
              </s.HiddenMobile>
            </s.FlexDiv>
            <s.MarginLeftDiv>
              <s.HiddenMobile>
                <Button model='primary-green'>cadastre-se gratuitamente</Button>
                <Button model='secondary' style={{ marginLeft: '14px' }}>
                  entrar
                </Button>
              </s.HiddenMobile>
              <s.HiddenTablet>
                <img src={MobileMenu} alt='Menu Mobile' />
              </s.HiddenTablet>
            </s.MarginLeftDiv>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar classes={{ root: classes.rootToolBarBottom }} />
    </>
  );
};

export default Header;
