import React from 'react';
import { ToastContainer } from 'react-toastify';
import * as s from './styles';

const Toast: React.FC = () => {
  return (
    <s.ContainerToast data-testid='toast-test-id'>
      <ToastContainer autoClose={5000} />
    </s.ContainerToast>
  );
};

export default Toast;
