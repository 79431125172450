import qs from 'qs';
import Cookies from 'js-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { baseURLLogin } from 'services/api';
import { ILoginEndRequest, ILoginPayload, ILoginResquest } from './types.store';

export const fetchLogin = createAsyncThunk('auth/authenticating', async (post: ILoginResquest, { rejectWithValue }) => {
  try {
    const values: ILoginEndRequest = {
      ...post,
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID || '',
      client_secret: process.env.REACT_APP_SECRET_ID || '',
    };

    const response: AxiosResponse<ILoginPayload> = await axios({
      url: baseURLLogin,
      method: 'post',
      data: qs.stringify(values),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    Cookies.set('cpf', post.username);
    Cookies.set('accessToken', response.data.access_token);

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
