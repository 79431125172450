import i18n from 'plugins/i18n';
import * as yup from 'yup';

export const schemaRegister = yup.object().shape({
  password: yup
    .string()
    .required()
    .test('is-valid', i18n.t('registerForm.password_error'), (value: string | undefined) => {
      return passwordValidation1(value || '') && passwordValidation2(value || '') && passwordValidation3(value || '');
    }),
  confirm_password: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], i18n.t('registerForm.confirm_password_error')),
});

export const passwordValidation1 = (password: string): boolean => {
  return password.length >= 8;
};

export const passwordValidation2 = (password: string): boolean => {
  return [...password.matchAll(/[a-z]/g)].length >= 2;
};

export const passwordValidation3 = (password: string): boolean => {
  return [...password.matchAll(/[0-9]/g)].length >= 2;
};
