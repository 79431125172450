import React, { FC, memo } from 'react';

import { ILoading } from './types';
import { ComponentContainer, LoadingContainer, LoadingBar, LegendText, IconAnimated } from './styles';

const Loading: FC<ILoading> = ({
  color = 'blue4',
  width = 28,
  height = 21,
  type = 'spinner',
  legend = '',
  placement = 'top',
  textAlign = 'left',
  ...rest
}) => {
  if (type === 'spinner') {
    return <IconAnimated role='img' name='loading' color={color} width={width} height={height} data-testid='loading-spinner' {...rest} />;
  }

  return (
    <ComponentContainer {...rest}>
      {legend && (
        <LegendText type='headlineLight' color='gray5' placement={placement} textAlign={textAlign} data-testid='legend'>
          {legend}
        </LegendText>
      )}
      <LoadingContainer>
        <LoadingBar color={color} data-testid='loading-bar' />
      </LoadingContainer>
    </ComponentContainer>
  );
};

export default memo(Loading);
