import styled, { css } from 'styled-components';
import { IText } from './types';

const truncated = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextStyle = styled.p<IText>`
  color: ${({ theme, color }) => theme.colors[color ?? 'gray5']};
  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ textTransform }) => textTransform};
  font-weight: ${({ bold }) => (bold ? 'normal' : '300')};
  margin: ${({ margin }) => margin} ${({ truncate }) => truncate && truncated};
  ${({ theme, type }) => theme.fontTypes[type ?? 'bodyLight'] || theme.fontTypes.bodyLight}
`;
