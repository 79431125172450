import React from 'react';
import Text from 'components/uikit/Text';
import { IconExporter } from 'components';
import { ToastifyContent, ToastifyWrapperTexts } from 'components/uikit/Toast/styles';
import { toast } from 'react-toastify';

export const Toast = (type: 'success' | 'error' | 'default', title: string, subtitle: string): React.ReactText => {
  const RenderTest = () => {
    return (
      <ToastifyWrapperTexts data-testid='toast-test-id'>
        <Text type='title' color='gray6'>
          {title}
        </Text>
        <Text type='bodyBold' color='gray5'>
          {subtitle}
        </Text>
      </ToastifyWrapperTexts>
    );
  };

  if (type === 'success') {
    return toast.success(
      <ToastifyContent>
        <IconExporter name='check' color='green3' width={21} height={21} />
        <RenderTest />
      </ToastifyContent>,
    );
  }

  if (type === 'error') {
    return toast.error(
      <ToastifyContent>
        <IconExporter name='information' color='magenta2' width={21} height={21} />
        <RenderTest />
      </ToastifyContent>,
    );
  }

  return toast.info(
    <ToastifyContent>
      <IconExporter name='add' color='gray4' width={21} height={21} />
      <RenderTest />
    </ToastifyContent>,
  );
};
