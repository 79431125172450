import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { NoSsr, StylesProvider } from '@material-ui/core';
import i18n from 'plugins/i18n';
import { I18nextProvider } from 'react-i18next';

import theme from 'styles/theme';
import store from 'store';

interface IAppProviders {
  children: React.ReactChild;
}

const AppProviders: React.FC<IAppProviders> = ({ children }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <StylesProvider injectFirst>
          <NoSsr>
            <GridThemeProvider gridTheme={theme.grid}>{children}</GridThemeProvider>
          </NoSsr>
        </StylesProvider>
      </I18nextProvider>
    </ThemeProvider>
  </Provider>
);

export default AppProviders;
