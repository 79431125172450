import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { Button, IconExporter, Input, Text } from 'components';

import { yupResolver } from '@hookform/resolvers/yup';

import Visibility from '@material-ui/icons/Visibility';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { ReactComponent as LogoPlatformPorto } from 'assets/images/LogoPlatformPorto.svg';

import { mediaQueries } from 'styles/theme';

import { schemaRegister, passwordValidation1, passwordValidation2, passwordValidation3 } from './utils';
import { IFormInputs } from './types';
import * as s from './styles';

const Register: React.FC = () => {
  const { t } = useTranslation();

  const [isPassword, setIsPassword] = React.useState<boolean>(true);
  const [isConfirmPassword, setIsConfirmPassword] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const matchesSmUp = useMediaQuery(`@media ${mediaQueries.smUp} `);

  const {
    handleSubmit,
    register,
    formState,
    formState: { errors },
    watch,
  } = useForm<IFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(schemaRegister),
    defaultValues: { password: '', confirm_password: '' },
  });
  const onSubmit = (data: IFormInputs) => {
    console.log(data);
  };

  const isDisabled = React.useCallback((): boolean => {
    if (!formState.isDirty || errors.password || errors.confirm_password) {
      return true;
    }

    return false;
  }, [errors, formState]);

  const hasErrorPassport = React.useCallback((): boolean => {
    if (errors.password && formState.touchedFields.password) {
      return true;
    }

    return false;
  }, [errors, formState]);

  const hasErrorConfirmPassport = React.useCallback((): boolean => {
    if (errors.confirm_password && formState.touchedFields.confirm_password) {
      return true;
    }

    return false;
  }, [errors, formState]);
  const password = watch('password');
  const typePasswordInput: string = isPassword ? 'password' : 'text';
  const passwordSuffix: JSX.Element = !isPassword ? <Visibility /> : <VisibilityOff />;

  const typeConfirmPasswordInput: string = isConfirmPassword ? 'password' : 'text';
  const confirmPassrwordSuffix: JSX.Element = !isConfirmPassword ? <Visibility /> : <VisibilityOff />;

  const iconRule = React.useCallback(
    (isValid: boolean) => {
      if (isValid) {
        return <IconExporter name='taskAlt' width='21' height='21' color='green3' />;
      }
      if (formState.touchedFields.password) {
        return <IconExporter name='highlightOff' width='21' height='21' color='magenta2' />;
      }

      return <IconExporter name='breadcrumb' width='20' height='12' color='gray5' />;
    },
    [formState],
  );

  return (
    <s.Container>
      <s.ButtonExit />
      <s.ButtonReturn />
      <s.ContainerForm data-testid='container-form'>
        <s.ContainerCenter data-testid='container-center'>
          <s.BackgroundBall />
          {matchesSmUp && <s.LogoConquista />}
          <s.WrapperForm data-testid='wrapper-form' style={{ paddingTop: !matchesSmUp ? '60px' : '0' }}>
            <s.RegisterForm onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Text type='heading4Bold' color='gray6'>
                  {t('register_form.title')}
                </Text>
                <Text type='headlineLight' color='gray5'>
                  {t('register_form.sub_title')}
                </Text>
              </div>
              <Input
                data-testid='input-component-password'
                label={t('register_form.password')}
                placeholder={t('register_form.placeholder')}
                type={typePasswordInput}
                suffix={passwordSuffix}
                onClickSuffix={() => setIsPassword(!isPassword)}
                helperText={hasErrorPassport() ? t('register_form.password_error') : ''}
                error={!!errors.password}
                {...register('password')}
              />
              <Input
                label={t('register_form.confirm_password')}
                placeholder={t('register_form.placeholder')}
                type={typeConfirmPasswordInput}
                suffix={confirmPassrwordSuffix}
                onClickSuffix={() => setIsConfirmPassword(!isConfirmPassword)}
                helperText={hasErrorConfirmPassport() ? t('register_form.confirm_password_error') : ''}
                error={hasErrorConfirmPassport()}
                {...register('confirm_password')}
              />
              <div>
                <Text type='headlineLight' color='gray5'>
                  {t('register_form.password_rules')}
                </Text>
                <s.PassportRuleItem type='bodyBold' color='gray6'>
                  {iconRule(passwordValidation1(password))}
                  <Text type='caption' color='gray5'>
                    {t('register_form.rule_1')}
                  </Text>
                </s.PassportRuleItem>
                <s.PassportRuleItem type='bodyBold' color='gray6'>
                  {iconRule(passwordValidation2(password))}
                  <Text type='caption' color='gray5'>
                    {t('register_form.rule_2')}
                  </Text>
                </s.PassportRuleItem>
                <s.PassportRuleItem type='bodyBold' color='gray6'>
                  {iconRule(passwordValidation3(password))}
                  <Text type='caption' color='gray5'>
                    {t('register_form.rule_3')}
                  </Text>
                </s.PassportRuleItem>
              </div>
              <Button model='primary-blue' disabled={isDisabled()} type='submit' isLoading={isLoading}>
                {t('register_form.button')}
              </Button>
            </s.RegisterForm>
          </s.WrapperForm>
          <s.Logo>
            <LogoPlatformPorto />
          </s.Logo>
        </s.ContainerCenter>
      </s.ContainerForm>
    </s.Container>
  );
};

export default Register;
